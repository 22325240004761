.logo {
  height: 32px;
  margin: 14px;
  background: url('../app-logo-128x128.png') no-repeat center;
  background-size: contain;
  cursor: pointer;
}

.site-layout .site-layout-background {
  background: #001529;
}